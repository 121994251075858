.App {
  text-align: center;
  background-color: rgb(0, 8, 20);
  ;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* Navbar.css */
.navbar {
  background: linear-gradient(to right, #0B60B0, #52D3D8, #40A2D8);
  color: #fff;
}

.navbar a {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  color: #2c2828;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.w-100 {
  height: -webkit-fill-available;
  width: 100% !important;
  vertical-align: middle;
  box-sizing: border-box;
  /* Apply box-sizing to the element and its pseudo-elements */
}

.w-100 *,
.w-100 ::after,
.w-100 ::before {
  box-sizing: inherit;
  /* Inherit box-sizing for all descendants */
}


.custom-pl {
  padding-left: 50px;
  /* Default padding */
}

@media (max-width: 992px) {
  .custom-pl {
    padding-left: 15px;
    /* Override padding for smaller screens */
  }
}


.about {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 1rem;
  margin-bottom: 2rem;
  background-color: rgb(0, 8, 20);
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-top: 2rem;
  margin-right: auto;
  margin-left: auto;
}

/* Responsive adjustments */
@media (max-width: 775px) {
  .about {
    flex-direction: column;
    /* Change to column layout on small screens */
    align-items: center;
    /* Align items to the start on small screens */
    text-align: center;
    /* Center align text on small screens */
    padding-right: var(--bs-gutter-x, .5rem);
    /* Adjust padding */
    padding-left: var(--bs-gutter-x, .5rem);
    /* Adjust padding */
  }
}


.about-item-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.about-item-1 .w-100 {
  width: 100%;
  /* Default width */
}

@media (min-width: 768px) {
  .about-item-1 {
    flex: 0 0 50%;
    /* Adjust as needed */
    max-width: 50%;
    /* Adjust as needed */
  }
}

.about-item-2 {
  text-align: left;
}

.about-exp {
  width: 140px;
  height: 140px;
  color: var(--white);
  background-color: rgb(68, 164, 208);
  border-radius: 6px;
  display: grid;
  justify-content: center;
  align-content: center;
  position: absolute;
  right: -12px;
  bottom: 12px;
  z-index: 1;
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .about-exp {
    width: 100px;
    /* Adjust width for smaller screens */
    height: 100px;
    /* Adjust height for smaller screens */
    right: -8px;
    /* Adjust position */
    bottom: 8px;
    /* Adjust position */
  }
}





.navbar-nav .nav-item a svg {
  width: 38px;
  height: 38px;
  fill: var(--text-dark);
  margin: 0px auto;
}

.main-header .logo img {
  height: 72px;
}

.main-header .navbar-expand-lg .navbar-nav .nav-link {
  padding: 10px 16px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-dark);
  text-transform: capitalize;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: grid;
}

#main-header-2 .navbar-expand-lg .navbar-nav .nav-link:hover {
  color: var(--primary-red) !important;
}

#main-header-2 .navbar-expand-lg .navbar-nav .nav-link:hover svg {
  fill: var(--primary-red) !important;
}


.flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Align items vertically */
}

.flex-container.row {
  flex-direction: row;
}

.flex-container.gx-md-5.row>* {
  margin-right: -1.25rem;
  /* Adjust as needed */
  margin-left: -1.25rem;
  /* Adjust as needed */
}

.flex-container.gx-md-5.row>*>* {
  padding-right: 1.25rem;
  /* Adjust as needed */
  padding-left: 1.25rem;
  /* Adjust as needed */
}

.home-cta {
  padding: 32px 16px;
  text-align: center;
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
}

.home-cta a {
  margin-top: 3rem;
  /* color: #F41120 !important;
  text-decoration: underline !important; */
  cursor: pointer;

}

.product-card {
  width: 100%;
  max-width: 300px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* background: #ECF5FF; */
  /* background-color: rgb(0, 8, 20); */
  text-align: left;
  border-radius: 8px;
  padding: 12px;
}

@media (max-width: 1200px) {
  .product-card {
    width: 200px;
  }
}

@media (max-width: 768px) {
  .product-card {
    width: 300px;
  }
}

@media (max-width: 1000px) {
  .product-card {
    width: 250px;
  }
}



.product-img {
  width: 300px;
  height: 200px !important;
  max-width: 100%;
  margin: auto;
  /* Set the desired height */
  display: block;
  object-fit: cover;
  /* Maintain aspect ratio and cover the entire container */
  padding: 7px;
  border-radius: 15px;
}

img {
  overflow-clip-margin: content-box;
  overflow: clip;
}

.product-info {
  padding: 15px;
}

.product-title {
  font-size: 18px;
  margin: 0;
}

.product-description {
  margin: 10px 0;
}

.product-price {
  font-weight: bold;
  margin: 0;
}

.trending-house {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

a {
  cursor: pointer;
}


.main-title-2 h2 {
  color: white;
  font-size: 2.375rem;
  font-weight: 600;
  letter-spacing: -0.38px;
  text-align: center;
}


@media (max-width: 768px) {
  .main-title-2 h2 {
    font-size: 1.5rem;
  }
}

/* .main-title-2 h2 span {
  color: #F41120;
} */

.main-title-2 {
  margin-top: 2rem;
  margin-bottom: 40px;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.services {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

.service-up {
  position: absolute;
  background: linear-gradient(
    118.19deg,
    #1fa2ff -3.62%,
    #12d8fa 50.44%,
    #a6ffcb 104.51%
  );
  opacity: 0.2;
  filter: blur(34px);
  border-radius: 100%;
  transform: matrix(1, 0, -0.03, 1, 0, 0);
}

.services-card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: black;
  padding: 15px;
  background-color: #e1e1e1;
  
  /* background-color: rgb(51 65 85); */
  border-radius: 8px;
  text-align: center;
  min-height: 387px;
  margin-bottom: 24px;
}

.codeblock2 {
  position: absolute;
  width: 372.95px;
  height: 257.05px;
  left: calc(50% - 372.95px / 2 - 76.53px);
  top: calc(50% - 257.05px / 2 - 47.47px);
  border-radius: 100%;
  background: linear-gradient(
    118.19deg,
    #1fa2ff -3.62%,
    #12d8fa 50.44%,
    #a6ffcb 104.51%
  );
  opacity: 0.2;
  filter: blur(34px);
  transform: matrix(1, 0, -0.03, 1, 0, 0);
}

@media (min-width: 576px) {
  .services-card {
    width: 30%;
  }
}

@media (min-width: 611px) {
  .services-card {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .services-card {
    width: 40%;
  }
}

@media (min-width: 1000px) {
  .services-card {
    width: 30%;
  }
}

.services-card img {
  max-width: 74px;
  margin-bottom: 16px;
}


.services-card h3 {
  color: #F41120;
}

h3 {
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 12px;
}

.counter-sec {
  background-color: #F41120;
  padding: 50px 0px;
  margin-bottom: 2rem;
  color: var(--white);
}

.contact {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  gap: 6rem;
  background-color: whitesmoke;
  width: 80%;

}

.contact-card {
  display: flex;
  margin: 1rem;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

form {
  display: block;
  margin-top: 0em;
  unicode-bidi: isolate;
}


@media (max-width: 768px) {
  .container {
    width: 100%;
    padding-right: var(--bs-gutter-x, 1.5rem);
    padding-left: var(--bs-gutter-x, 1.5rem);
  }
}

h4 {
  font-size: var(--font-18);
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: var(--font-weight-600);
}

.custom-bg-color {
  background-color: rgb(51, 65, 85);
}

@keyframes wave {
  0%, 60%, 100% {
      transform: translateY(0);
  }
  30% {
      transform: translateY(-0.25em);
  }
}

.dot-animation {
  display: inline-block;
  animation: wave 1.5s infinite;
}

